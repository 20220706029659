<template>
    <div class="tagsView">
        <el-tabs v-model="activeTagsView" type="card" closable @tab-remove="removeTab">
            <el-tab-pane
                :key="n.code"
                v-for="n in data"
                :label="n.name"
                :name="n.linkTo||n.href"
            >
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import {mapState} from "vuex";
import store from "@/store";
export default {
    components: {},
    data() {
        return {
            activeTagsView:'',
        };
    },
    computed: {
        ...mapState({
            data: state => state.tagsView.data,
        })
    },
    watch: {
        $route:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (to){
                this.activeTagsView = to.meta.linkTo || to.meta.href;
                store.commit({
                    type: 'tagsView/SET_DATA',
                    data:to.meta,
                });
            }
        },
        activeTagsView:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (res){
                this.$router.push(res);
            }
        },
    },
    mounted(){

    },
    methods:{
        removeTab(r){
            if(r!=this.activeTagsView){
                store.commit({
                    type: 'tagsView/DEL_DATA',
                    path:r,
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";
.tagsView{
    padding:3px 20px 1px 20px;
    background:#F8F9FB;
    /deep/.el-tabs__nav {
        font-size:0;
        border:none;
    }
    /deep/.el-tabs__header {
        margin:0;
        border:none;
        .el-tabs__item {
            position:relative;
            border:#E4E7ED solid 1px;
            border-bottom:none;
            border-radius:3px 3px 0 0;
            overflow:hidden;
            line-height:47px;
            height:47px;
            padding:0 13px;
            background:#fff;
            +.el-tabs__item {
                margin-left:10px;
            }
            .el-icon-close {
                width:14px;
            }
            &.is-active {
                .el-icon-close {
                    display:none;
                }
                &:after {
                    content:"";
                    position:absolute;
                    top:0;
                    left:0;
                    right:0;
                    height:4px;
                    background:$themeColor;
                }
            }
        }
    }
}
</style>
